<template>
  <div class="normal-text bg-dark-theme text-center">
    <div class="bg-1">
      <div class="d-block m-0">
        <LandingPageHeader :trasparentHeader="true" />
        <div class="clearfix"></div>
      </div>

      <div class="h-md-30vh">&nbsp;</div>
      <section class="landing_view d-block">
        <div class="row d-flex justify-content-center">
          <div class="col-12 col-md-8">
            <h1 class="text-light large-text fnt-tny">{{ $t('ULTIMATE AI POWERED MARKETPLACE') }}</h1>
            <h3 class="text-light large-text-2">
              {{ $t('Experience Next-Level Business Growth with MOM') }}
              <br>
              {{ $t('The Future of AI-Driven Solutions') }}
            </h3>
            <div class="h-md-3vh"></div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="row d-flex justify-content-center">
              <div class="col-12 col-md-auto">
                  <b-link class="custom-design custom-design-black custom-design-fw text-light font-weight-bold" :to="userRedirect">MOM AI - ASK ANYTHING</b-link>                
              </div>
              <div class="col-12 col-md-auto">
                  <b-link class="custom-design custom-design-black custom-design-fw text-light font-weight-bold" :to="shopRedirect">MOM Shop - SELL ONLINE</b-link>                
              </div>
              <div class="col-12 col-md-auto">
                  <b-link class="custom-design custom-design-black custom-design-fw text-light font-weight-bold" :to="providerRedirect">MOM Provider - GET ONLINE</b-link>
              </div>
            </div>
            <div class="row d-flex justify-content-center">
              <div class="col-12">
                <b-link href="https://play.google.com/store/apps/details?id=com.momitnow.twa" target="_blank">
                  <b-img :src="require('@/assets/images/landing/google-play-store.png')" class="gplay_img" fluid />
                </b-link>
              </div>
              <div class="h-5vh"></div>
              <div class="col-12 col-md-8 d-none d-md-block">
                <p>
                  Are you ready to catapult your business into the era of digital innovation? MOM App is here to
                  revolutionize your
                  company's operations with cutting-edge AI tools and solutions. Harness the intelligence of advanced
                  technology to give
                  your business the competitive edge it deserves.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>


      <div class="h-md-70vh h-20vh">&nbsp;</div>
      <section class="landing_view">
        <div class="row">
          <div class="col-12 col-md-8 mx-md-auto d-none d-md-block">
            <p>Step into Tomorrow with MOM App - Your Digital Transformation Ally</p>
          </div>
          <div class="col-12 col-md-8 mx-md-auto">
            <p>
              Dive into the Digital Age: Embrace the full spectrum of MOM App's AI-powered features, from streamlined
              e-commerce to effective service management. This robust platform is your complete solution for a
              modernized
              business model. 
            </p>
          </div>
          <div class="col-12 col-md-8 mx-md-auto">
            <p>
              Join the Progressive MOM Community: Connect with us and embark on your journey to business excellence.
              Let MOM App lead the way in your digital transformation, providing a seamless transition into the
              innovative
              landscape of tomorrow.
            </p>
          </div>
        </div>
        <div class="h-md-10vh">&nbsp;</div>
      </section>
    </div>

    
    <section class="landing_section">
      <div class="row mb-4 mb-md-1" v-for="(section, sk) in landingPageTiels" :key="`l_section_${sk}`">
        <div v-if="sk > 0" class="h-md-10vh"></div>
        <div class="col-12 col-md-11 mx-md-auto">
          <div class="row d-flex justify-content-around align-items-center">
            <div class="col-12 col-md-4">
              <div class="row">
                <div class="col-10 mx-auto">
                  <b-img :src="require(`@/assets/images/landing/Website/${section.img}`)" class="home_img" fluid />
                </div>
              </div>
            </div>
            <div class="col-12 col-md-8">
              <div class="row mb-md-3 mb-1 mt-2 mt-md-0">
                <div class="col-12">
                  <b-link class="custom-design text-light px-md-5 font-weight-bold" :to="{name: section.route}">{{ section.title }}</b-link>
                </div>
              </div>
              <div class="row">
                <div class="col-12" v-for="(section_para, spk) in section.paragraphs" :key="`${spk}_${sk}_sec_para`">
                  <p>{{ section_para }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="h-md-10vh"></div>
    </section>


    <div class="bg-2">
      <section class="landing_section">
        <div class="row">
          <div class="col-12 col-md-9 mx-md-auto">
            <h2 class="large-text-2 text-center">
              The MOM App Advantage: Holistic Tools for Unprecedented Success
            </h2>
            <p>
              Data-Driven Insight: Take advantage of our advanced analytics suite to make informed decisions that drive 
              business growth and strategic direction.
            </p>
            <p>
              Promotions and Customer Loyalty: Attract and retain a loyal customer base with enticing promotion and 
              loyalty programs tailored to your audience.
            </p>
            <p>
              Expansive Market Outreach: Whether you're targeting local customers or aiming for global expansion, MOM 
              App equips you with the tools to reach and engage your desired market effectively.
            </p>
          </div>
        </div>
        <div class="h-5vh h-md-30vh"></div>
        <div class="row">
          <div class="col-12 col-md-9 mx-md-auto">
            <h2 class="large-text-2">
              Why Opt for MOM App?
            </h2>
            <p>
              Rapid, Versatile, and Easy-to-Adopt Solutions
            </p>
            <p>
              Quick Setup: Launch your online presence swiftly with our three-step setup process.
            </p>
            <p>
              Adaptable Growth: Whether you're a budding startup or an established enterprise, MOM App scales with your 
              business, ensuring long-term adaptability and growth.
            </p>
          </div>
        </div>
        <div class="h-5vh h-md-30vh"></div>
        <div class="row">
          <div class="col-12 col-md-9 mx-md-auto">
            <h1 class="large-text fnt-tny text-center text-uppercase">Welcome to the MOM App - Your Gateway to AI-Enhanced Performance</h1>
            <div class="h-md-5vh h-1vh"></div>
            <div class="row">
              <div class="col-12">
                <b-link class="custom-design custom-design-black text-light px-md-5 font-weight-bold" :to="userRedirect">MOM AI - ASK ANYTHING</b-link>
              </div>
              <div class="col-12">
                <b-link class="custom-design custom-design-black text-light px-md-5 font-weight-bold" :to="shopRedirect">MOM Shop - SELL ONLINE</b-link>
              </div>
              <div class="col-12">
                <b-link class="custom-design custom-design-black text-light px-md-5 font-weight-bold" :to="providerRedirect">MOM Provider - GET ONLINE</b-link>
              </div>
              <div class="col-12 d-none d-md-block">
                <b-link href="https://play.google.com/store/apps/details?id=com.momitnow.twa" target="_blank">
                  <b-img :src="require('@/assets/images/landing/google-play-store.png')" class="gplay_img" fluid />
                </b-link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="h-md-90vh"></div>
      <LandingPageFooter :trasparentFooter="true"  />
      <AppFooterBlack :trasparentFooter="true"  />
    </div>
  </div>
</template>

<script>
import LandingPageFooter from '@core/layouts/components/LandingPageFooter.vue'
import AppFooterBlack from '@core/layouts/components/AppFooterBlack.vue'
import LandingPageHeader from '@core/layouts/components/LandingPageHeader.vue'
import LandingSVG from './LandingSVG.vue'

const landingPageTiels = [
  {
    img: 'MOM AI.webp',
    title: 'MOM AI - ASK ANYTHING',
    route: 'apps-login',
    paragraphs: [
      'Order Management Made Easy: Take control of your orders with our user-friendly and powerful order management system, allowing for increased efficiency and',
      'Integrated Cloud POS: Seamlessly handle point-of-sale transactions with our cloud-based POS system, ensuring smooth operations and accurate sales tracking.',
      'Master HR Management: Streamline your workforce administration with our efficient HR management tools, driving productivity and employee satisfaction.',
      'Flawless Delivery Operations: Enjoy a hassle-free logistics experience with our smart delivery management system, providing reliable and timely order fulfillment.',
    ]
  },
  {
    img: 'MOM Shop.webp',
    title: 'MOM SHOP - SELL ONLINE',
    route: 'apps-shop-login',
    paragraphs: [
      `Engage Customers with AI: MOM AI is perfect for creating engaging and impactful
        content across various platforms like TikTok, Instagram, and your website.
        Transform your marketing strategy with MOM AI, where innovation meets creativity.
      `,
      `Image Generation with Visual Brilliance: Create compelling, high-quality images in an
        instant with MOM App's powerful AI Image Generator, and captivate your audience.
      `,
      `Voice Transformation Technology: With our text-to-speech and speech-to-text
        features, bridge the communication gap and speak directly to your customers in
        their preferred method of interaction.
      `,
      `Effortless Coding: Simplify your development processes with AI-generated code
        snippets, enhancing productivity and reducing the time-to-market for your software.
      `,
    ]
  },
  {
    img: 'MOM Service.webp',
    title: 'MOM PROVIDER - GET ONLINE',
    route: 'apps-provider-login',
    paragraphs: [
        `
          Instant Visibility: Join our platform and instantly showcase your services to a vast 
          online audience. 
        `,

        `
          Effortless Booking: Simplify service booking with our intuitive interface. Customers 
          can book your services in a few clicks, thanks to our user-friendly design.
        `,

        `
          Secure Payments: Worried about payments? MOM Provider App ensures secure 
          and hassle-free transactions, with funds directly deposited into your company 
          account.  
        `,

        `
          Data-Driven Growth: Make informed decisions with our powerful analytics tools. 
          Track performance, analyze trends, and refine your services for maximum 
          profitability
        `
    ]
  },
]

export default {
  components: {
    LandingSVG,
    LandingPageFooter,
    AppFooterBlack,
    LandingPageHeader,
  },
  directives: {
    lazyBackground: {
      bind: (el, binding) => {
        el.style.backgroundImage = `url('${binding.value}')`;
      }
    }
  },
  data: () => ({
    landingPageTiels
  }),
  computed: {
    userRedirect: {
      get(){
        if(window.SuperApp.getters._isUser()){
          return {name: 'user-dashboard'}
        }
        return {name: 'apps-login'}
      }
    },
    shopRedirect: {
      get(){
        if (window.SuperApp.getters._isStore()){
          return { name: 'shop-dashboard' }
        }
        return {name: 'apps-shop-login'}
      }
    },
    providerRedirect: {
      get(){
        if (window.SuperApp.getters._isProvider() && !window.SuperApp.getters._isAgent()){
          return { name: 'provider-requests'}
        }
        else if (window.SuperApp.getters._isProvider() && window.SuperApp.getters._isAgent()){
          return { name: 'provider-agent-dashboard' }
        }
        return {name: 'apps-provider-login'}
      }
    },
  },
  methods: {
    goToService(url) {
      if (url) {
        this.$router.push({
          path: url,
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/landing-scoped.scss';

.bg-1 {
  background-color: #040612;
  background-image: url('~@/assets/images/landing/Website/webp/man-stands-front-mountain-looks-landscape.webp');
  background-position: center center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents repeating the image */
  background-size: cover; /* Ensures the image covers the entire element */
  padding-bottom: 1.5rem;
}


</style>

<style lang="scss">
@import '~@core/scss/base/landing.scss';
@import '~@core/scss/base/landing-page.scss';
@media screen and (min-width: 768px) {
  .bg-2 {
    background-image: url('~@/assets/images/landing/Website/webp/man-is-walking-into-door-leading-magic-world.webp');
    background-position: center center; /* Centers the image */
    background-repeat: no-repeat; /* Prevents repeating the image */
    background-size: cover; /* Ensures the image covers the entire element */
    color: #FFFFFF;
  }
}
</style>
